import React, { useState, useEffect } from 'react';
import './App.css';
import { Plus, Minus, Check, X } from 'lucide-react';

const UpperSectionInput = ({ label, onChange, value, diceValue, color }) => {
  const handleIncrement = () => {
    if (value === null) {
      onChange(1);
    } else if (value === 0) {
      onChange(null);
    } else if (value < 5) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value === null) {
      onChange(0);
    } else if (value > 1) {
      onChange(value - 1);
    } else if (value === 1) {
      onChange(null);
    }
  };

  const score = value === null ? '' : value * diceValue;
  const isMaxReached = value === 5;

  return (
    <div className={`flex items-center justify-between p-2 rounded-lg mb-2 ${color}`}>
      <label className="text-sm font-medium text-white">{label}</label>
      <div className="flex items-center h-8">
        <button 
          onClick={handleDecrement} 
          className="w-8 h-full bg-white rounded-l hover:bg-gray-100 transition-colors flex items-center justify-center"
        >
          <Minus size={16} />
        </button>
        <div className="w-12 h-full bg-white border-t border-b flex items-center justify-center font-semibold">
          {score}
        </div>
        <button 
          onClick={handleIncrement} 
          className="w-8 h-full bg-white rounded-r hover:bg-gray-100 transition-colors flex items-center justify-center"
          disabled={isMaxReached}
        >
          <Plus size={16} />
        </button>
      </div>
    </div>
  );
};

const BonusYahtzeeInput = ({ label, onChange, value, color }) => {
  const handleIncrement = () => {
    onChange((value || 0) + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  const score = value * 100;

  return (
    <div className={`flex items-center justify-between p-2 rounded-lg mb-2 ${color}`}>
      <label className="text-sm font-medium text-white">{label}</label>
      <div className="flex items-center h-8">
        <button 
          onClick={handleDecrement} 
          className="w-8 h-full bg-white rounded-l hover:bg-gray-100 transition-colors flex items-center justify-center"
        >
          <Minus size={16} />
        </button>
        <div className="w-16 h-full bg-white border-t border-b flex items-center justify-center font-semibold">
          {score}
        </div>
        <button 
          onClick={handleIncrement} 
          className="w-8 h-full bg-white rounded-r hover:bg-gray-100 transition-colors flex items-center justify-center"
        >
          <Plus size={16} />
        </button>
      </div>
    </div>
  );
};

const LowerSectionInput = ({ label, onChange, value, score, color }) => {
  const handleClick = () => {
    if (value === null) {
      onChange(score);  // First click: set to preset score
    } else if (value === score) {
      onChange(0);  // Second click: set to zero (red cross)
    } else {
      onChange(null);  // Third click: back to blank
    }
  };

  return (
    <div className={`flex items-center justify-between p-2 rounded-lg mb-2 ${color}`}>
      <label className="text-sm font-medium text-white">{label}</label>
      <button
        onClick={handleClick}
        className="w-16 h-8 bg-white rounded flex items-center justify-center font-semibold"
      >
        {value === score ? (
          <>
            <Check className="text-green-500 mr-1" size={16} />
            {value}
          </>
        ) : value === 0 ? (
          <>
            <X className="text-red-500 mr-1" size={16} />
            0
          </>
        ) : (
          ''
        )}
      </button>
    </div>
  );
};

const CustomScoreInput = ({ label, onChange, value, color, max = 30 }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value === null ? '' : value.toString());

  useEffect(() => {
    setTempValue(value === null ? '' : value.toString());
  }, [value]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    const parsedValue = parseInt(tempValue, 10);
    onChange(isNaN(parsedValue) ? null : Math.min(parsedValue, max));
  };

  const handleChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <div className={`flex items-center justify-between p-2 rounded-lg mb-2 ${color}`}>
      <label className="text-sm font-medium text-white">{label}</label>
      {isEditing ? (
        <input
          type="number"
          value={tempValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          className="w-16 h-8 p-1 text-right border rounded"
          autoFocus
        />
      ) : (
        <button
          onClick={handleClick}
          className="w-16 h-8 bg-white rounded flex items-center justify-center font-semibold"
        >
          {value === null ? '' : value}
        </button>
      )}
    </div>
  );
};

const TotalRow = ({ label, value, bonus }) => (
  <div className="flex justify-between font-medium text-lg bg-gray-100 p-2 rounded-lg mb-2">
    <span>{label}</span>
    <div className="flex items-center">
      {bonus !== 0 && (
        <span className={`text-sm font-bold mr-2 ${bonus > 0 ? 'text-green-500' : 'text-red-500'}`}>
          {bonus > 0 ? `+${bonus}` : bonus}
        </span>
      )}
      <span className="font-semibold">{value}</span>
    </div>
  </div>
);

const initialScores = {
  ones: null, twos: null, threes: null, fours: null, fives: null, sixes: null,
  threeOfAKind: null, fourOfAKind: null, fullHouse: null,
  smallStraight: null, largeStraight: null, yahtzee: null, chance: null,
  bonusYahtzees: 0
};

const encodeScores = (scores) => {
  return Object.entries(scores)
    .map(([key, value]) => `${key}:${value === null ? 'null' : value}`)
    .join(',');
};

const decodeScores = (hash) => {
  const pairs = hash.split(',');
  return pairs.reduce((acc, pair) => {
    const [key, value] = pair.split(':');
    acc[key] = value === 'null' ? null : parseInt(value, 10);
    return acc;
  }, {...initialScores});
};

const Modal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h3 className="text-lg font-semibold mb-4">Are you sure you want to reset?</h3>
        <p className="mb-6 text-gray-600">This action will reset all scores to their initial values. This cannot be undone.</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Reset Scores
          </button>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [scores, setScores] = useState(() => {
    const hash = window.location.hash.slice(1);
    return hash ? decodeScores(hash) : initialScores;
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setScores(decodeScores(hash));
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    const hash = encodeScores(scores);
    window.history.replaceState(null, null, `#${hash}`);
  }, [scores]);

  const updateScore = (key, value) => {
    setScores(prev => ({ ...prev, [key]: value }));
  };

  const resetScores = () => {
    setScores(initialScores);
    setIsModalOpen(false);
  };

  const upperCategories = [
    { key: 'ones', label: 'Ones', diceValue: 1, color: 'bg-blue-500' },
    { key: 'twos', label: 'Twos', diceValue: 2, color: 'bg-blue-600' },
    { key: 'threes', label: 'Threes', diceValue: 3, color: 'bg-blue-700' },
    { key: 'fours', label: 'Fours', diceValue: 4, color: 'bg-blue-800' },
    { key: 'fives', label: 'Fives', diceValue: 5, color: 'bg-blue-900' },
    { key: 'sixes', label: 'Sixes', diceValue: 6, color: 'bg-blue-950' },
  ];

  const calculateUpDown = (count, diceValue) => {
    if (count === null) return 0;
    return (count - 3) * diceValue;
  };

  const upperTotal = upperCategories.reduce((sum, { key, diceValue }) => 
    sum + (scores[key] === null ? 0 : scores[key] * diceValue), 0);
  
  const upperBonus = upperCategories.reduce((sum, { key, diceValue }) => 
    sum + calculateUpDown(scores[key], diceValue), 0);

  const bonus = upperTotal >= 63 ? 35 : 0;

  const lowerTotal = 
    (scores.threeOfAKind || 0) +
    (scores.fourOfAKind || 0) +
    (scores.fullHouse || 0) +
    (scores.smallStraight || 0) +
    (scores.largeStraight || 0) +
    (scores.yahtzee || 0) +
    (scores.chance || 0);

  const yahtzeeBonus = scores.bonusYahtzees * 100;
  const grandTotal = upperTotal + bonus + lowerTotal + yahtzeeBonus;

  return (
    <div className="max-w-sm mx-auto p-4 bg-gradient-to-b from-gray-100 to-gray-200 shadow-lg rounded-xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Yahtzee Score Sheet</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Reset
        </button>
      </div>
      
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-3 text-gray-700">Upper Section</h3>
        {upperCategories.map(({ key, label, diceValue, color }) => (
          <UpperSectionInput
            key={key}
            label={label}
            value={scores[key]}
            onChange={(value) => updateScore(key, value)}
            diceValue={diceValue}
            color={color}
          />
        ))}
        <TotalRow label="Upper Total" value={upperTotal} bonus={upperBonus} />
        <TotalRow label="Bonus" value={bonus} />
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-3 text-gray-700">Lower Section</h3>
        <CustomScoreInput
          label="Three of a Kind"
          value={scores.threeOfAKind}
          onChange={(value) => updateScore('threeOfAKind', value)}
          color="bg-green-600"
        />
        <CustomScoreInput
          label="Four of a Kind"
          value={scores.fourOfAKind}
          onChange={(value) => updateScore('fourOfAKind', value)}
          color="bg-green-700"
        />
        <LowerSectionInput
          label="Full House"
          value={scores.fullHouse}
          onChange={(value) => updateScore('fullHouse', value)}
          score={25}
          color="bg-green-800"
        />
        <LowerSectionInput
          label="Small Straight"
          value={scores.smallStraight}
          onChange={(value) => updateScore('smallStraight', value)}
          score={30}
          color="bg-green-800"
        />
        <LowerSectionInput
          label="Large Straight"
          value={scores.largeStraight}
          onChange={(value) => updateScore('largeStraight', value)}
          score={40}
          color="bg-green-900"
        />
        <LowerSectionInput
          label="Yahtzee"
          value={scores.yahtzee}
          onChange={(value) => updateScore('yahtzee', value)}
          score={50}
          color="bg-green-900"
        />
        <CustomScoreInput
          label="Chance"
          value={scores.chance}
          onChange={(value) => updateScore('chance', value)}
          color="bg-green-700"
        />
        <TotalRow label="Lower Total" value={lowerTotal} />
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-3 text-gray-700">Yahtzee Bonus</h3>
        <BonusYahtzeeInput
          label="Bonus Yahtzees"
          value={scores.bonusYahtzees}
          onChange={(value) => updateScore('bonusYahtzees', value)}
          color="bg-purple-700"
        />
        <TotalRow label="Yahtzee Bonus" value={yahtzeeBonus} />
      </div>

      <div className="bg-gray-800 text-white p-3 rounded-lg text-xl flex justify-between items-center">
        <span className="font-bold">Grand Total:</span>
        <span className="text-2xl font-extrabold">{grandTotal}</span>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={resetScores}
      />
    </div>
  );
}

export default App;
